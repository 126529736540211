<script>
import MixedGrid from '@phoenix/mixins/components/mixedGrid/MixedGrid';
import ModelCard from './ModelCard.vue';
import ModelSinglePush from './ModelSinglePush.vue';
import { map } from 'lodash-es';

export default {
  components: { ModelCard, ModelSinglePush },

  mixins: [MixedGrid],

  props: {
    isCentered: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 6,
    },
    headingTag: {
      type: String,
      default: 'h2',
    },
  },

  computed: {
    loadMoreClasses() {
      return [
        'actions actions--center mt-md',
        map(this.maxPage, (page, size) => {
          const prefix = size !== 'xs' ? `${size}:` : '';
          const hidden = prefix ? 'hidden' : 'force-hidden';
          return this.page >= page ? `${prefix}${hidden}` : '';
        }),
        { 'force-hidden': this.filteredColumnsCount === 0 },
      ];
    },

    subHeadingTag() {
      return this.headingTag === 'h1' ? 'h2' : 'h3';
    },

    gridClasses() {
      return [
        'product-card-grid grid gap-xs sm:gap-sm md:gap-base lg:gap-md xl:gap-lg',
        { 'product-card-grid--centered': this.isCentered },
      ];
    },
  },

  methods: {
    getDisplayedColumnClasses(column) {
      if (!this.shouldDisplayLoadMore) {
        return [];
      }

      return map(column.page, (page, size) => {
        const prefix = size !== 'xs' ? `${size}:` : '';
        return this.page < page ? `${prefix}force-hidden` : ``;
      });
    },

    getComponent(template) {
      switch (template) {
        case 'product':
          return ModelCard;
      }
    },
  },
};
</script>

<template>
  <div class="container">
    <div :class="['headings', { 'headings--center': isCentered }]">
      <component :is="headingTag" v-if="title" class="h3">{{ title }}</component>
      <component :is="subHeadingTag" v-if="subtitle" class="headings__subtitle h6">{{ subtitle }}</component>
    </div>

    <div class="filters mt-base md:mt-base lg:mt-lg mb-base"></div>

    <ModelSinglePush v-if="filteredColumnsCount === 1" class="single-push" v-bind="columns[0].data"></ModelSinglePush>

    <div v-else :class="gridClasses">
      <component
        :is="getComponent(column.template)"
        v-for="column in filteredColumns"
        :key="column.data.itemId"
        :additional-classes="getDisplayedColumnClasses(column)"
        v-bind="column.data"
        data-cy="mixed-grid-item"
        :data-cy-item="column.template"
      >
      </component>
    </div>
    <ul v-if="shouldDisplayLoadMore" :class="loadMoreClasses">
      <li role="presentation">
        <button
          type="button"
          class="btn btn--secondary actions--center mt-md"
          data-cy="mixed-grid-see-all"
          @click="seeAll"
        >
          <span class="btn__text">{{ $t('Button.seeAll', { nb: nbOfMaxItems }) }}</span>
        </button>
      </li>
    </ul>
  </div>
</template>
