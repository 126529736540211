<script>
import PictureLazy from '../common/PictureLazy';

export default {
  components: {
    PictureLazy,
  },

  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    url: {
      type: String,
      required: true,
    },
    image: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    variationCount: {
      type: Number,
      required: true,
    },
    additionalClasses: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    getModelCardClasses() {
      return ['product-card', ...this.additionalClasses];
    },
  },
};
</script>

<template>
  <div :class="getModelCardClasses">
    <div class="picture product-card__picture picture--contain">
      <picture-lazy :lazy="true" v-bind="image" />
    </div>

    <div class="product-card__description">
      <a :href="url" class="product-card__link expand-target">
        <div class="product-card__name">{{ name }}</div>
        <div class="product-card__collection"></div>

        <div class="product-price product-price--small">
          <span class="product-price__value">
            {{ $t('Models.variationCount', { variations: variationCount }) }}
          </span>
        </div>
      </a>
    </div>
  </div>
</template>
