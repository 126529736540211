<script>
import PictureLazy from '../common/PictureLazy';

export default {
  components: {
    PictureLazy,
  },

  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    url: {
      type: String,
      required: true,
    },
    image: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    variationCount: {
      type: Number,
      required: true,
    },
    additionalClasses: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    getModelSinglePushClasses() {
      return ['single-push', ...this.additionalClasses];
    },
  },
};
</script>

<template>
  <div :class="getModelSinglePushClasses">
    <div class="single-push__column">
      <a :href="url" class="picture single-push__image">
        <picture-lazy :lazy="true" v-bind="image" style="pointer-events: inherit" />
      </a>
    </div>

    <div class="single-push__column">
      <div class="headings">
        <h2 class="h3">{{ name }}</h2>

        <h3 class="headings__subtitle h6"></h3>
      </div>

      <div class="single-push__infos">
        {{ $t('Models.variationCount', { variations: variationCount }) }}
      </div>

      <a :href="url" class="btn btn--secondary single-push__cta">
        <span class="btn__text">{{ $t('Models.discoverMore') }}</span>
      </a>
    </div>
  </div>
</template>
